import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Header from '../header/Header.component';
import Footer from '../footer/Footer.component';
import FooterMenuItems from '../footer-menu/FooterMenuItems.component';
import { securitySelectors } from '../../redux/security';
import { NavigationConfig, NavigationSectionType } from '../../models/navigation.models';
import { getNavItems } from '../../utilities/navigation/navigation.utility';
import GlobalMessage from '../common/global-message/GlobalMessage.component';

export interface LayoutProps {
  children: ReactNode;
  navigateSections?: NavigationSectionType[];
}

const Layout = (props: LayoutProps) => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isAuthorized = useSelector(securitySelectors.isAuthorized([]));
  const userRoles = useSelector(securitySelectors.getRoles);
  const navSections: NavigationSectionType[] = props.navigateSections || getNavItems(isLoggedIn, isAuthorized, userRoles);
  const navConfig: NavigationConfig = { navSections };

  return (
    <>
      <div className="c-tsp-layout__header">
        <Header navConfig={navConfig} />
      </div>
      <GlobalMessage />
      <div className="o-els-flex-layout o-els-flex-layout--column@tablet o-els-flex-layout--left">
        <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
          <main id="main" role="main">{props.children}</main>
          <FooterMenuItems />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
