import * as yup from 'yup';
import moment from 'moment';
import { isValidedDate, isAfterDate } from '../utilities/app/app.utility';

export const RoutePath = {
  home: '/home',
  submitCreditRequest: '/submit-credit-request',
  trackCreditRequest: '/track-credit-request',
  security: {
    login: '/login',
    logout: '/logout'
  },
  error: {
    pageNotFound: '/page-not-found',
    notAuthorized: '/not-authorized',
    notAuthorizedApproved: '/not-authorized-approved',
    notAuthorizedRosterSubmission: '/not-authorized-roster-submission',
    productNotFound: '/product-not-found',
    expiredLink: '/expired',
    expiredCohortRoster: '/expired-cohort-roster',
    expiredStudentPayment: '/expired-student-payment',
    studentPaymentNotFound: '/student-payment-not-found',
    noPaymentDue: '/no-payment-due'
  },
  cohort: {
    productSearch: '/my-dashboard/cohort-detail/:opportunityId/:cohortId/product-selection',
    productReview: '/my-dashboard/cohort-detail/:opportunityId/:cohortId/review-selection',
    productDetail: '/my-dashboard/cohort-detail/:opportunityId/:cohortId/product/:productId',
    reviewProductSelections: '/my-dashboard/review-product-selections/:opportunityId/:cohortId',
    facultyRosterSubmission: '/my-dashboard/faculty-roster-submission/:guid',
    studentRosterSubmission: '/my-dashboard/student-roster-submission/:guid',
    reviewInstallmentCohortCountApproval: '/my-dashboard/review-cohort-counts/:opportunityId/:cohortId/:installmentId',
    studentPayment: '/my-dashboard/student-payment/:installmentId',
    studentPaymentAddress: '/my-dashboard/student-payment/:installmentId/billing-address',
    studentPaymentReview: '/my-dashboard/student-payment/:installmentId/review',
    studentPaymentConfirm: '/my-dashboard/student-payment/:installmentId/confirm',
    studentPaymentPaid: '/my-dashboard/student-payment/:installmentId/paid-success',
    studentPaymentCancel: '/my-dashboard/student-payment/:installmentId/cancel',
    studentPaymentProcess: '/my-dashboard/student-payment/:installmentId/payment'
  },
  myDashboard: {
    home: '/my-dashboard',
    inFlightCohortDashboard: '/my-dashboard?ifc=:ifc',
    opportunityDetail: '/my-dashboard/opportunity-detail/:opportunityId',
    cohortDetail: '/my-dashboard/cohort-detail/:opportunityId/:cohortId',
    reviewCohortCounts: '/my-dashboard/review-cohort-counts/:opportunityId/:invoiceId',
    creditRequestQueue: '/my-dashboard/credit-request-queue',
    billingDashboardQueue: '/my-dashboard/institution-invoice-queue'
  }
};

export const AppConstant = {
  redux: {
    ROUTER_STATE: 'router',
    CONFIG_STATE: 'configState',
    APP_STATE: 'appState',
    LANGUAGE_STATE: 'languageState',
    SECURITY_STATE: 'securityState',
    COHORT_STATE: 'cohortState',
    OPPORTUNITY_STATE: 'opportunityState',
    PRODUCT_SEARCH: 'productSearchState',
    INVOICE_STATE: 'invoiceState',
    INSTALLMENT_STATE: 'installmentState',
    OPPORTUNITIES_STATE: 'opportunitiesState',
    STUDENT_PAYMENT_STATE: 'studentPaymentState'
  },
  Locker: {
    EvolveUserCacheKey: 'evolveUser',
    EolsUserIdCacheKey: 'userId',
    EolsExternalUserIdCacheKey: '_euid',
    RoleCacheKey: 'role',
    LoggedInCacheKey: 'loggedIn',
    TSPStateCacheKey: 'tspState',
    TSPStateVersionCacheKey: 'tspStateVersion',
    UserNameCacheKey: 'username',
    CampaignCode: 'campaignCode',
    IsSalesDemoRole: 'isSalesDemoRole'
  }
};

export const UserRole = {
  Admin: 'admin',
  EducationConsultant: 'salesRep',
  Manager: 'manager',
  Student: 'student',
  Faculty: 'faculty',
  FacultyRegistered: 'faculty_registered',
  SystemEditor: 'system_editor',
  Administrator: 'administrator',
  AngelAdministrator: 'angel_administrator',
  AngelUser: 'angel_user',
  AngelSalesdemo: 'angel_salesdemo',
  Staff: 'staff',
  TspEducationConsultant: 'tsp_edu_consultant',
  TspAdministrator: 'tsp_administrator',
  TspSuperUser: 'tsp_super_user',
  TspReadOnlyUser: 'tsp_read_only',
  TspBillingSpecialist: 'tsp_billing_specialist',
  TspStudentDashboardUser: 'tsp_student_dashboard_user',
  HesiSupportStakeholder: 'cms_hesi_support_stakeholder'
};

export const CookieTypes = {
  UserType: 'role',
  rememberMe: 'rememberMe',
  EA_COOKIE: 'loginThroughEA'
};

export const DEFAULT_ASSESS_ROLES = [
  UserRole.SystemEditor,
  UserRole.Administrator,
  UserRole.Manager,
  UserRole.AngelAdministrator,
  UserRole.AngelUser,
  UserRole.AngelSalesdemo,
  UserRole.Staff
];

export const Analytics = {
  defaultAnalyticsConfig: {
    departmentId: '',
    departmentName: '',
    environment: '',
    productName: 'ps',
    businessUnit: 'els:ns:otr',
    cmsName: '',
    language: 'en',
    userId: 'eols:',
    accountName: 'eols:',
    accountId: 'eols:'
  },
  pageType: {
    home: 'hp',
    submitCreditRequest: 'cr-sc',
    trackCreditRequest: 'cr-rc',
    productDetail: 'pp-pd',
    productReview: 'ra-ps',
    reviewProductSelections: 'rp-rpa',
    facultyRosterSubmission: 'rs-fr',
    studentRosterSubmission: 'rs-sr',
    productSearch: 'pp-sp',
    cohortDetail: 'cp-cd',
    opportunityDetail: 'op-od',
    creditRequestQueue: 'crq-cr',
    billingDashboardQueue: 'bdq-bd',
    reviewInstallmentCohortCountApproval: 'ra-cca',
    studentPayment: 'sp',
    myDashboard: 'dp',
    login: 'ap-lp',
    logout: 'ap-lo',
    pageNotFound: 'ap-nf',
    notAuthorized: 'ap-na',
    notAuthorizedApproved: 'ap-naa',
    notAuthorizedRosterSubmission: 'ap-na-rs',
    productNotFound: 'pp-nf',
    expiredLink: 'ra-el',
    expiredCohortRoster: 'rs-el',
    expiredStudentPayment: 'sp-el',
    studentPaymentNotFound: 'sp-nf',
    noPaymentDue: 'sp-np'
  }
};

export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_TIME_FORMAT = 'MM-DD-YYYY HH:mm';
export const DATE_FORMAT_MMDDYYYY = 'MM/DD/YYYY';
export const DATE_FORMAT_MMMMDDYYYY = 'MMMM DD YYYY';
export const DATE_FORMAT_MDYYYY = 'M/D/YYYY';
export const DATE_TIME_FORMAT_MDYYYHMA = 'M/D/YYYY h:m a';
export const DATE_TIME_FORMAT_MDYYYYHMMA = 'M/D/YYYY h:mm a';
export const LABEL_TIME_UTC = 'UTC';
export const DATE_FORMAT_MMMMD_COMMA_YYYY = 'MMMM D, YYYY';

export const ServicePath = {
  tokenServicePath: '/token-service'
};

export const KeyCode = {
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  BACKSPACE: 8,
  TAB: 9
};

export const MINIMUM_LENGTH_CREDITQUEUE_SEARCH_TEXT = 5;

export const EvolveLinks = {
  MyEvolve: 'myEvolve',
  SalesDemoUrl: '/lo/salesdemo/',
  EVOLVE_SUPPORT_LINK: 'https://service.elsevier.com/app/overview/evolve',
  EVOLVE_SUPPORT_HUB: 'https://service.elsevier.com/app/contact/supporthub/evolve/'
};

export const PRODUCT_TYPES = {
  VST: ['ebook_ac', 'ebook_ia', 'custom_ebook_ia'],
  INTEL: ['ebook_kno_ac', 'ebook_kno_ia', 'custom_ebook_kno_ia'],
  CEL_GLOBAL_OR_SELF_STUDY: ['cel_global', 'cel_selfstudy'],
  EAQ: ['eaq_ia', 'eaqng_ia'],
  NO_COURSE_ID: ['pending', 'hesi', 'hesi_ng_course', 'compass_course', 'kno', 'sherpath_course', 'sherpath_book_organized_course', 'vst', 'shadowhealth_course'],
  REQUIRE_LMS_CONFIG: ['eaq_ia', 'eaqng_ia', 'hesi_assessment_nextgen'],
  PENDING_SELF_ENROLLMENT: ['sherpath_ia', 'sherpath_book_organized', 'hesi_assessment_nextgen', 'hesi_compass_examprep'],
  HESI_LS: ['case_study', 'patient_reviews', 'practice_test'],
  HESI_LIVE_REVIEW: ['bill_only'],
  NCO: ['nursing_concepts_ia'],
  SIMCHART: ['simulation_sim_chart'],
  SIM_SLS: ['simulation_sls_ia'],
  SIM_NG_COURSE_BASED: ['simulation_sim_chart_ng'],
  ONLINE_COURSE: ['custom_course_ia', 'course_ia'],
  EVOLVE_RESOURCE: ['resource'],
  BILL_ONLY: ['bill_only']
};

export const EVOLVE_PRODUCT_TYPES = {
  HESI_ASSESSMENT_NG: 'hesi_assessment_nextgen',
  HESI_COMPASS_EXAMPREP: 'hesi_compass_examprep',
  SHERPATH_COURSE: 'sherpath_course',
  HESING_COURSE: 'hesi_ng_course',
  COMPASS_COURSE: 'compass_course',
  CANVAS_COURSE: 'canvassso',
  SHERPATH_IA: 'sherpath_ia',
  SHERPATH_VANTAGE_IA: 'sherpath_vantage_ia',
  SHERPATH_BOOK_ORGANIZED: 'sherpath_book_organized',
  SHERPATH_BOOK_ORGANIZED_COURSE: 'sherpath_book_organized_course',
  SHERPATH_INTEGRATED_IA: 'sherpath_integrated_ia',
  SHADOW_HEALTH: 'shadow_health',
  SHADOW_HEALTH_COURSE: 'shadowhealth_course',
  CLINICAL_PRACTICE_READINESS_ASSESSMENT: 'clinical_practice_readiness_assessment_ia',
  COMBINATION_COURSE: 'combinationcourse_ia'
};

export const CohortWorkflowStatuses = [{
  key: ['ProductSelections'],
  name: 'Product Selections'
}, {
  key: ['DigitalMappingConsultation'],
  name: 'Digital Mapping Consultation'
}, {
  key: ['CohortSetup', 'CohortSetUp'],
  name: 'Cohort Set Up'
}, {
  key: ['EducationAndTraining'],
  name: 'Education and Training'
}, {
  key: ['StudentSetup', 'StudentSetUp'],
  name: 'Student Set Up'
}, {
  key: ['CustomerSuccess'],
  name: 'Customer Success'
}, {
  key: ['HESITesting'],
  name: 'HESI Testing'
}];

export const CODE_REDEMPTION = {
  PRODUCT_TYPES: {
    VST: ['ebook_ia', 'custom_ebook_ia'],
    SHERPATH: [
      EVOLVE_PRODUCT_TYPES.SHERPATH_IA,
      EVOLVE_PRODUCT_TYPES.SHERPATH_BOOK_ORGANIZED,
      EVOLVE_PRODUCT_TYPES.SHERPATH_INTEGRATED_IA,
      EVOLVE_PRODUCT_TYPES.SHERPATH_VANTAGE_IA
    ],
    INTEL: ['ebook_kno_ia', 'custom_ebook_kno_ia']
  },
  REQUIRED_SECTIONS: {
    VST: { type: 'vst', required: true },
    INTEL: { type: 'intel', required: true },
    SHIPPING_ADDRESS: { type: 'vst', required: true }
  }
};

export enum SubmitCreditRequestTabs {
  SubmitProgramSolutionTab = 0,
  TrackProgramSolutionTab = 1
}

export const LMS_PLAFTFORMS = {
  EVOLVE_UNIQUE: 'unique',
  EVOLVE_GLOBAL: 'global',
  OTHER_LMS: 'other_lms',
  NO_LMS: 'nolms',
  NO_LMS_UNIQUE: 'nolms_unique',
  LOX2: 'learningobject_x2'
};

export const LMS_HOST_NAMES = {
  evolveContentPlus: 'Hosted on Evolve, Content Plus Course Tools',
  evolveContentOnly: 'Hosted on Evolve, Content Only',
  canvasOnEvolve: 'Hosted on Canvas provided by Elsevier'
};

export const ADD_TO_BUTTONS = ['eprod_add_to_cart', 'ebook_add_to_cart', 'faculty_hardgoods'];

export const PRODUCT_VALIDATION_SCHEMA = {
  term: yup.number().min(1, 'The term is required.'),
  courseSetupDate: yup.date().nullable(true)
    .when('$isCourseSetupDateUpdated', (isCourseSetupDateUpdated: boolean) => {
      if (!isCourseSetupDateUpdated) {
        return yup.date().nullable(true);
      }
      return yup.date().nullable(true)
        .transform((value, originalValue) => (originalValue && !isValidedDate(originalValue) ? new Date('') : value))
        .min(yup.ref('$currentDate'), 'The course setup date must be a date in the future.')
        .typeError('Please enter valid course setup date. <br />Invalid date format. Course setup date must be in MM/DD/YYYY format.');
    }),
  studentEnrollmentDate: yup.date().nullable(true)
    .when(['courseSetupDate', '$isStudentEnrollmentDateUpdated', '$currentDate'],
      (courseSetupDate, isStudentEnrollmentDateUpdated, currentDate) => {
        let enrollmentDateSchema = yup.date().nullable(true);
        if (!isStudentEnrollmentDateUpdated) {
          if (!courseSetupDate || !isValidedDate(courseSetupDate)) {
            return enrollmentDateSchema;
          }
          return enrollmentDateSchema.min(moment(courseSetupDate).startOf('day'), 'Student enrollment date must be greater than or equal to course setup date.');
        }
        enrollmentDateSchema = enrollmentDateSchema.transform((value, originalValue) => (originalValue && !isValidedDate(originalValue) ? new Date('') : value))
          .typeError('Please enter valid student enrollment date. <br />Invalid date format. Student enrollment date must be in MM/DD/YYYY format.');
        if (courseSetupDate && isAfterDate(courseSetupDate, currentDate)) {
          return enrollmentDateSchema.min(courseSetupDate, 'Student enrollment date must be greater than or equal to course setup date.');
        }
        return enrollmentDateSchema.min(currentDate, 'The student enrollment date must be a date in the future.');
      }),
  schoolCourseName: yup.string().nullable(true)
    .transform((v) => (v.trim() === '' ? null : v.trim()))
    .min(2, 'The school course name must be at least 2 characters')
};

export const SHADOW_HEALTH_VALIDATION_SCHEMA = {
  title: yup.string().transform(v => (v ? v.trim() : v)).max(100, 'The course title must be at most 100 characters').required('The course title is required.'),
  number: yup.string().transform(v => (v ? v.trim() : v)).max(100, 'The course number must be at most 100 characters').required('The course number is required.'),
  projectedCourseEnrollment: yup.lazy((value) => (
    value === '' ?
      yup.string() :
      yup.number()
        .integer('Projected Enrollment must be a valid number.')
        .min(0, 'Projected Enrollment must be greater than or equal to 0.'))),
  enrollmentStartDate: yup.date().nullable().transform((v, o) => (o === '' ? null : v)).required('The enrollment start date is required.')
    .min(yup.ref('$enrollmentStartDate'), 'Enrollment start date must be greater than or equal to current date.')
    .typeError('Please enter valid enrollment start date. <br />Invalid date format. Enrollment start date must be in MM/DD/YYYY format.'),
  courseStartDate: yup.date().nullable().transform((v, o) => (o === '' ? null : v)).required('The course start date is required.')
    .typeError('Please enter valid course start date. <br />Invalid date format. Course start date must be in MM/DD/YYYY format.')
    .when('enrollmentStartDate', (enrollmentStartDate, schema) =>
      (isValidedDate(enrollmentStartDate) ?
        schema.min(enrollmentStartDate, 'Course start date must be greater than or equal to enrollment start date.') :
        schema.min(yup.ref('$courseStartDate'), ''))),
  courseEndDate: yup.date().nullable().transform((v, o) => (o === '' ? null : v)).required('The course end date is required.')
    .typeError('Please enter valid course end date. <br />Invalid date format. Course end date must be in MM/DD/YYYY format.')
    .when('courseStartDate', (courseStartDate, schema) => {
      const minDate = isValidedDate(courseStartDate) ? moment(courseStartDate).endOf('day') : moment().endOf('day');
      return schema.min(minDate, 'Course end date must be greater than course start date.');
    }),
  affiliatedNursingProgram: yup.string().required('Please select a valid affiliated nursing program value.')
};

export const LMS_VALIDATION_SCHEMA = {
  selectedCourseTools: yup.number().required('Please select the LMS you want your course hosted.'),
  selectedLMSPlatform: yup.string().required('Please select the LMS platform.'),
  deliveryMethod: yup.string().required('Please select the delivery method.'),
  LMSCustomName: yup.string().required('The Learning management system is required.'),
  LMSAdminEmail: yup.string().email('The admin email is invalid.'),
  platformComboCourseIsbn: yup.string().required('The combo course is required.'),
  platformExistingCourseId: yup.string().required('The Course ID is required.').matches(/^\w+$/, 'The Course ID is invalid.'),
  projectedCourseEnrollment: yup.lazy((value) => (
    value === '' ?
      yup.string() :
      yup.number()
        .integer('Projected course enrollment must be a valid number.')
        .min(0, 'Projected course enrollment must be greater than or equal to 0.'))),
  programId: yup.string()
    .matches(/^\d{0,10}$/, 'Enter valid program id.'),
  courses: yup.array(
    yup.object(SHADOW_HEALTH_VALIDATION_SCHEMA),
  ).nullable()
};
export enum TSPConfirmModalType {
  DELETE = 'delete',
  CONFIRM = 'confirm'
}

export const PLATFORM_COMBO_COURSE = 'PLATFORM_COMBO_COURSE';
export const UNDER_PLATFORM_COMBO_COURSE = 'UNDER_PLATFORM_COMBO_COURSE';
export const DEFAULT_TERM_VALUE = 0;
export const REDIRECT_URL = 'redirect_url';
export const VALIDATE_RESPONSE_ERROR_TYPE = {
  NORMAL: 'normal',
  REVIEW_APPROVAL: 'review_approval', // review cohort count approval and review product selection
  ROSTER_SUBMISSION: 'roster_submission',
  STUDENT_PAYMENT: 'student_payment'
};

export const EVOLVE_ADDRESS_TYPE = {
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
  HOME_ADDRESS: 'HOME_ADDRESS'
};

export const COUNTRY = {
  US: 'US',
  CA: 'CA'
};

export const usStates = [
  {
    value: 'AL',
    name: 'Alabama'
  },
  {
    value: 'AK',
    name: 'Alaska'
  },
  {
    value: 'AZ',
    name: 'Arizona'
  },
  {
    value: 'AR',
    name: 'Arkansas'
  },
  {
    value: 'CA',
    name: 'California'
  },
  {
    value: 'CO',
    name: 'Colorado'
  },
  {
    value: 'CT',
    name: 'Connecticut'
  },
  {
    value: 'DE',
    name: 'Delaware'
  },
  {
    value: 'DC',
    name: 'District Of Columbia'
  },
  {
    value: 'FL',
    name: 'Florida'
  },
  {
    value: 'GA',
    name: 'Georgia'
  },
  {
    value: 'HI',
    name: 'Hawaii'
  },
  {
    value: 'ID',
    name: 'Idaho'
  },
  {
    value: 'IL',
    name: 'Illinois'
  },
  {
    value: 'IN',
    name: 'Indiana'
  },
  {
    value: 'IA',
    name: 'Iowa'
  },
  {
    value: 'KS',
    name: 'Kansas'
  },
  {
    value: 'KY',
    name: 'Kentucky'
  },
  {
    value: 'LA',
    name: 'Louisiana'
  },
  {
    value: 'ME',
    name: 'Maine'
  },
  {
    value: 'MD',
    name: 'Maryland'
  },
  {
    value: 'MA',
    name: 'Massachusetts'
  },
  {
    value: 'MI',
    name: 'Michigan'
  },
  {
    value: 'MN',
    name: 'Minnesota'
  },
  {
    value: 'MS',
    name: 'Mississippi'
  },
  {
    value: 'MO',
    name: 'Missouri'
  },
  {
    value: 'MT',
    name: 'Montana'
  },
  {
    value: 'NE',
    name: 'Nebraska'
  },
  {
    value: 'NV',
    name: 'Nevada'
  },
  {
    value: 'NH',
    name: 'New Hampshire'
  },
  {
    value: 'NJ',
    name: 'New Jersey'
  },
  {
    value: 'NM',
    name: 'New Mexico'
  },
  {
    value: 'NY',
    name: 'New York'
  },
  {
    value: 'NC',
    name: 'North Carolina'
  },
  {
    value: 'ND',
    name: 'North Dakota'
  },
  {
    value: 'OH',
    name: 'Ohio'
  },
  {
    value: 'OK',
    name: 'Oklahoma'
  },
  {
    value: 'OR',
    name: 'Oregon'
  },
  {
    value: 'PA',
    name: 'Pennsylvania'
  },
  {
    value: 'RI',
    name: 'Rhode Island'
  },
  {
    value: 'SC',
    name: 'South Carolina'
  },
  {
    value: 'SD',
    name: 'South Dakota'
  },
  {
    value: 'TN',
    name: 'Tennessee'
  },
  {
    value: 'TX',
    name: 'Texas'
  },
  {
    value: 'UT',
    name: 'Utah'
  },
  {
    value: 'VT',
    name: 'Vermont'
  },
  {
    value: 'VA',
    name: 'Virginia'
  },
  {
    value: 'WA',
    name: 'Washington'
  },
  {
    value: 'WV',
    name: 'West Virginia'
  },
  {
    value: 'WI',
    name: 'Wisconsin'
  },
  {
    value: 'WY',
    name: 'Wyoming'
  }
];

export const canadaProvinces = [
  {
    value: 'AB',
    name: 'Alberta'
  }, {
    value: 'BC',
    name: 'British Columbia'
  }, {
    value: 'MB',
    name: 'Manitoba'
  }, {
    value: 'NB',
    name: 'New Brunswick'
  }, {
    value: 'NL',
    name: 'Newfoundland and Labrador'
  }, {
    value: 'NT',
    name: 'Northwest Territories'
  }, {
    value: 'NS',
    name: 'Nova Scotia'
  }, {
    value: 'NU',
    name: 'Nunavut'
  }, {
    value: 'ON',
    name: 'Ontario'
  }, {
    value: 'PE',
    name: 'Prince Edward Island'
  }, {
    value: 'QC',
    name: 'Quebec'
  }, {
    value: 'SK',
    name: 'Saskatchewan'
  }, {
    value: 'YT',
    name: 'Yukon'
  }
];

export const billingCountries = [
  {
    value: COUNTRY.US,
    name: 'United States of America'
  }
];

export const TSP_APP_CONTEXT = '/tsp';

export const EVOLVE_NOTIFICATION_TIMEOUT = 900000;

export const NOTIFY_MISSING_MSG_SCOPE = {
  COHORT: 'cohort',
  OPPORTUNITY: 'opportunity'
};

export enum AccessControlType {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Hide = 'hide',
  Show = 'show'
}

export const ReadOnlyViewRoles = [UserRole.TspReadOnlyUser];

export const COHORT_MANAGEMENT_SYSTEM = 'Cohort Management System';

export const AcceptFileType = {
  PDF: 'application/pdf'
};

export const STICKY_CELL_Z_INDEX = {
  DATA_CELL: 1,
  HEADER_CELL: 2
};

export const DEFAULT_GENERATED_SYSTEM_NAME = 'CMS Generated';

export const FACULTY_PERMISSIONS = {
  HESI_FACULTY_ORDERING: 'hesiFacultyOrdering',
  HESI_FACULTY_SCHEDULING: 'hesiFacultyScheduling',
  HESI_FACULTY_PROCTORING: 'hesiFacultyProctoring'
};

export const tspModalWrapperClass = 'c-tsp-modal__wrapper';
export const tspModalWindowClass = 'c-tsp-modal__window';

export const ENABLE_FEATURE = {
  STUDENT_GROUP: `${AppConstant.redux.APP_STATE}.enableStudentGroups`
};

export const INPUT_AMOUNT_PATTERN = /^\d+(\.\d{0,2})?$/;

export const INPUT_AMOUNT_MAXIMUM_VALUE = 99999999;

export const DEFAULT_SYSTEM_NAME = 'Salesforce';

export const DEFAULT_BLANK_VALUE = '-';

export const OTHER_LMS = 'Other LMS';

export const KEY_NAME_ESC = 'Escape';

export const KEY_EVENT_TYPE = 'keydown';
