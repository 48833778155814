import { get } from 'lodash';
import { EXAM_FIELD_PATH } from 'modules/hesi/redux/exam-order/exam-order.selectors';
import { PAYMENT_METHODS } from 'modules/hesi/constants/exam-order.constant';
import { ExamOrderItemModel } from '../../models/examOrder.model';
import { ExamProductModel, ProgramModel, PagingOptions } from '../../models/exam.model';

export const getTotalQuantity = programsSelected => {
  let sum = 0;
  programsSelected.forEach(program => {
    sum += Number(program.quantity);
  });
  return sum;
};

export const getTotalCartPrice = orderItems => orderItems.reduce((result, item) => result + (getTotalQuantity(item.programsSelected) * item.price), 0);

export const validateEmptyFieldInOrderItem = (orderItem, field) => {
  if (!orderItem || !orderItem.programsSelected) {
    return true;
  }
  return orderItem.programsSelected.find(program => !program[field] || program[field].length === 0);
};

export const getAllProgramIdsOfOrderItem = (orderItem: ExamOrderItemModel) => (orderItem && orderItem.programsSelected &&
  orderItem.programsSelected.map((program) => program.programId)) || [];

export const getAllProgramsNameOfOrderItem = (orderItem: ExamOrderItemModel, programs: ProgramModel[]) => {
  const programIds = getAllProgramIdsOfOrderItem(orderItem);
  const programsName = programIds.map(id => {
    const programItem = programs.find(program => String(program.programId) === id);
    return programItem ? programItem.programName : '';
  });
  return programsName && programsName.length > 0 && programsName.join(', ');
};

export const getAllFacultiesNameOfOrderItem = (orderItem: ExamOrderItemModel) => {
  const facultiesName = orderItem.programsSelected.map(program => program.facultyUserNames.map(faculty => `${faculty.lastName} ${faculty.firstName}`).join(', '));
  return facultiesName && facultiesName.length > 0 && facultiesName.join(', ');
};

export const generateOrderItemId = () => (Date.now());

export const isEmptyOrderItems = (orderItems) => (!orderItems || orderItems.length === 0);

export const getValueFromObjectByKey = (field: string, object?: {}) => {
  if (!object || !object[field]) {
    return '';
  }
  return object[field];
};

export const buildErrorMessage = (field, errors) => errors || { [field]: '' };
// Search
const isValueIncluded = (value: string | number, searchText: string) => (`${value}`).toLocaleLowerCase().includes(searchText.trim().toLowerCase());

const isFieldIncluded = (examListData: ExamProductModel, path: string, searchText: string) => {
  const value = get(examListData, path);
  return value && isValueIncluded(value, searchText);
};

export const filterExamsBySearchText = (searchText: string) => (examProducts: ExamProductModel[]) => examProducts && examProducts.filter(product =>
  (!searchText || (isFieldIncluded(product, EXAM_FIELD_PATH.NAME, searchText))));

export const filterExamsByProgramExamTypeFilter = (pagingOptions: PagingOptions) => (examProducts: ExamProductModel[]) => {
  if (!examProducts || examProducts.length === 0) {
    return examProducts;
  }

  const selectedProgramTypes = new Set(pagingOptions.selectedProgramTypes);
  const selectedAssessmentTypes = new Set(pagingOptions.selectedAssessmentTypes);

  return examProducts && examProducts.filter(product => product && product.programTypes && product.programTypes.some(pt => {
    const programTypeMatch = selectedProgramTypes.size === 0 || selectedProgramTypes.has(pt.programTypeId);
    const assessmentTypeMatch = selectedAssessmentTypes.size === 0 || pt.examTypes.some(examType => selectedAssessmentTypes.has(examType.assessmentType.assessmentTypeId));
    return programTypeMatch && assessmentTypeMatch;
  }));
};

export const getProgramNameByProgramId = (programId: number | string, programList: ProgramModel[]) => {
  const program = programList.find(item => item.programId === Number(programId));
  return program ? program.programName : '';
};

export const buildExamOrderPayload = (paymentInformation, orderItems, paymentMethod) => {
  let payload = {};
  const { orderingFacultyContactPhone, orderingFacultyContactPhoneExtension, purchaseOrderNumber, billingAddress, secondaryContactFullName,
    secondaryContactTitle, secondaryContactEmail } = paymentInformation;
  payload = {
    paymentMethod,
    programId: Number(orderItems && orderItems[0].programsSelected && orderItems[0].programsSelected[0].programId),
    poNumber: purchaseOrderNumber,
    contactPhoneNumber: orderingFacultyContactPhone,
    contactPhoneExtension: orderingFacultyContactPhoneExtension,
    examOrderItems: orderItems.map(item => {
      const { programsSelected, productId } = item;
      return {
        productId,
        programs: programsSelected.map(program => {
          const { programId, facultyUserNames, quantity } = program;
          return {
            programId: Number(programId),
            facultyUserNames: facultyUserNames.map(faculty => faculty.userName),
            quantity
          };
        })
      };
    })
  };

  if (secondaryContactFullName) {
    payload = {
      ...payload,
      secondaryContactFullName,
      secondaryContactTitle,
      secondaryContactEmail
    };
  }

  if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
    return {
      ...payload,
      billingAddress
    };
  }

  return {
    ...payload,
    poNumber: purchaseOrderNumber,
  };
};
