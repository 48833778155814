import React from 'react';
import { useDispatch } from 'react-redux';
import { ELSButton } from '../../../../components/common/els';
import hesiLogo from '../../assets/icons/HESILogo.svg';
import { navigateToHesiNG } from '../../utilities/navigation/navigation.utility';

const HESIHeader = () => {
  const dispatch = useDispatch();
  return (
    <header
    className="o-els-flex-layout
                 o-els-flex-layout--space-between
                 o-els-flex-layout--middle
                 c-els-header c-els-header__container
                 u-els-margin-top-1o2@mobile
                 u-els-margin-bottom-1o2@mobile
                 u-els-padding-none
                 c-tsp-hesi-header"
  >
      <div className="o-els-flex-layout o-els-flex-layout--middle c-tsp-hesi-header__navbar">
        <div className="o-els-flex-layout u-els-background-color-primary u-els-margin-right c-tsp-hesi-header__navbar__brand">
          <ELSButton type="link" onClick={() => dispatch(navigateToHesiNG('/#/instructor/order'))}>
            <img src={hesiLogo} alt="HESI" className="u-els-padding-left-2x c-tsp-hesi-header__logo" />
          </ELSButton>
        </div>
        <h3>Order HESI Exams</h3>
      </div>
    </header>
  );
};

export default HESIHeader;
