import withUseLoading from 'utilities/with-loading/withUseLoading';
import { sortBy } from 'lodash';
import { examOrderActions } from './exam-order.reducer';
import { searchExamProducts, getProductMetadata, validateOrderingFaculty, fetchPrograms } from '../../services/exam.service';
import { programTypeFields } from '../../constants/exam-order.constant';
import { appAsyncActions } from '../../../../redux/app';
import { getMessageBasedOnErrorType } from '../../../../utilities/error/error.utility';

export const checkAuthorizeExamOrder = () =>
  withUseLoading(async dispatch => {
    try {
      const { isAuthorized } = await validateOrderingFaculty();
      dispatch(examOrderActions.setAuthorizeExamOrder(isAuthorized));
    } catch (e) {
      dispatch(examOrderActions.setAuthorizeExamOrder(false));
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchProductMetadata = () =>
  withUseLoading(async dispatch => {
    try {
      const { programTypes } = await getProductMetadata();
      dispatch(examOrderActions.fetchProductMetadataSuccess(sortBy(programTypes, programTypeFields.programTypeName)));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchHESIProgramList = () =>
  withUseLoading(async dispatch => {
    try {
      const { programs } = await fetchPrograms();

      dispatch(examOrderActions.setPrograms(sortBy(programs, program => program.programName.toLowerCase())));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchExamProducts = (selectedProgramTypes = [], selectedAssessmentTypes = []) =>
  withUseLoading(async dispatch => {
    try {
      const response = await searchExamProducts(selectedProgramTypes, selectedAssessmentTypes);
      dispatch(examOrderActions.fetchExamProductsDataSuccess(response));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });
