import React, { Component, ReactNode } from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';

const MODAL_CLOSE_TRANS_DUR_IN_MS = 200;
const TABLET_MAX_WIDTH = 900;

interface SideDrawerModalProps {
  modalId: string;
  children: ReactNode;
  closeHandler: Function;
}

interface SideDrawerModalState {
  modalClosing: boolean;
}

export class SideDrawerModal extends Component<SideDrawerModalProps, SideDrawerModalState> {
  state = { modalClosing: false };
  listenerResize = null;

  constructor(props) {
    super(props);
    this.listenerResize = window.addEventListener('resize', this.handleListenerResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.listenerResize);
  }

  handleListenerResize = () => {
    if (window.innerWidth > TABLET_MAX_WIDTH && !this.state.modalClosing) {
      this.closeModal();
    }
  }

  closeModal = () => {
    const { modalId, closeHandler } = this.props;
    this.setState({ modalClosing: true });
    setTimeout(() => {
      closeHandler(modalId);
    }, MODAL_CLOSE_TRANS_DUR_IN_MS);
  }

  render() {
    const { children } = this.props;

    return (
      <div className={this.state.modalClosing ? 'c-els-header-modal--animate-close' : 'c-els-header--modal--animate-open'}>
        <div className="c-els-header-modal__sidebar">
          <div className="c-els-header-modal__close">
            <button
              type="button"
              className="c-els-header-modal__close-trigger u-els-debuttonize"
              onClick={() => this.closeModal()}
            >
              <ELSIcon name="close" size="1x" />
              <span className="u-els-hide-visually">Close this modal window</span>
            </button>
          </div>

          <div className="c-els-header-modal__menu-list">
            {children}
          </div>
        </div>
        <div className="c-tsp-side-drawer-model__overlay" aria-hidden="true" />
      </div>
    );
  }
}

export default SideDrawerModal;
