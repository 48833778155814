import { InstallmentStatuses } from 'constants/installment.constant';
import { InvoiceStatuses } from 'constants/invoice.constant';
import { isEmpty, find } from 'lodash';
import { EvolveInstallmentModel, InstallmentStatusModel } from 'models/installment.model';
import { isInstallmentAmountValueSelected } from 'utilities/app/app.utility';

export const isInstallmentInvoiceDateEditable = (installment: EvolveInstallmentModel) => {
  if (!installment.invoices || installment.invoices.length === 0) {
    return true;
  }

  const activeInvoice = find(installment.invoices, inv => !inv.isCredited);
  return !activeInvoice;
};

export const isInstallmentApproved = (installment: EvolveInstallmentModel) => !!installment.installmentApprovedStatus;

export const isInstallmentIncludeRevenueStatuses = (installmentLatestStatus: InstallmentStatusModel) => (
  isEmpty(installmentLatestStatus) ||
  installmentLatestStatus.id === InvoiceStatuses.RevenueAllocationFileGenerated.id ||
  installmentLatestStatus.id === InvoiceStatuses.RevenueAllocationUploadSuccess.id ||
  installmentLatestStatus.id === InvoiceStatuses.ReverseRevenueAllocationFileGenerated.id ||
  installmentLatestStatus.id === InvoiceStatuses.ReverseRevenueAllocationUploadSuccess.id ||
  installmentLatestStatus.id === InvoiceStatuses.ReverseRevenueAllocationFileGenerated.id
);

export const getApprovalStatusMessage = (installmentStatus: InstallmentStatusModel, installmentApprovedStatus?: InstallmentStatusModel) => {
  if (installmentApprovedStatus) {
    return 'Cohort Count Approved';
  }

  switch (installmentStatus.id) {
    case InstallmentStatuses.CohortCountsApproved.id:
      return 'Cohort Count Approved';
    case InstallmentStatuses.CohortCountsRejected.id:
      return 'Cohort Count Rejected';
    case InstallmentStatuses.CohortCountApprovalRequestSent.id:
      return 'Cohort Count Approval Request Sent';
    case InstallmentStatuses.CohortCountsUndone.id:
      return 'Cohort Count Undone';
    case InstallmentStatuses.ReminderCohortCountApprovalRequestSent.id:
      return 'Reminder: Cohort Count Approval Request Sent';
    default:
      return '-';
  }
};

export const isInstallmentRebilledNotYet = (installment: EvolveInstallmentModel) => installment && installment.remainingInstitutionAmount &&
  Number(installment.remainingInstitutionAmount) > 0;

export const getInstallmentInstitutionAmount = (installment: EvolveInstallmentModel) => {
  if (isInstallmentRebilledNotYet(installment)) {
    return installment.remainingInstitutionAmount;
  }
  return isInstallmentAmountValueSelected(installment.pspPurchasingType) ? installment.installmentAmountPerStudent : installment.institutionCostPerStudent;
};
