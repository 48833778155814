import { some } from 'lodash';
import { anonymousNavSections, authorizedNavSections, unauthorizedNavSections } from '../../constants/navigation.constant';
import { externalRedirectDelayInMs } from '../../config/app.config';
import { NavigationItemType, NavigationSectionType } from '../../models/navigation.models';

export const navigateToExternalUrl = (url, newWindow?) => {
  setTimeout(() => {
    if (newWindow) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }, externalRedirectDelayInMs);
};

const isNavigateItemAuthorized = (session: NavigationItemType | NavigationSectionType, userRoles: string[] = []) => {
  if (!session.roles || session.roles.length === 0 || userRoles.length === 0) {
    return true;
  }
  return some(userRoles, role => session.roles.includes(role));
};

export const filterNavItemsByUserRoles = (userRoles: string[], items: NavigationSectionType[] = []) =>
  items.filter(section => isNavigateItemAuthorized(section, userRoles)).map(section => {
    if (!section.items || section.items.length === 0) {
      return section;
    }

    const newItems = section.items.filter(item => isNavigateItemAuthorized(item, userRoles));
    return {
      ...section,
      items: newItems
    };
  });

export const getNavItems = (isLoggedIn: boolean, isAuthorized: boolean, userRoles: string[] = []) => {
  if (!isLoggedIn) {
    return anonymousNavSections;
  }
  let navItems = authorizedNavSections;
  if (!isAuthorized) {
    navItems = unauthorizedNavSections;
  }

  return filterNavItemsByUserRoles(userRoles, navItems);
};
