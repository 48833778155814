import { Redirect, Route, Switch } from 'react-router';
import { createUserRoutes } from 'utilities/route/route.utility';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';
import { ALCRoutePath } from '../constants/hesi.constants';
import ExamOrderHomePage from '../pages/exam-order-home-page/ExamOrderHomePage.page';
import ExamOrderDashboardHomePage from '../pages/exam-order-dashboard/ExamOrderDashboardHome.page';
import ExamOrderHistoryHomePage from '../pages/exam-order-history/ExamOrderHistoryHome.page';
import SecurityPage from '../pages/security/SecurityPage.page';
import { ALCRoutes } from '../routes/hesi.routes';

import 'modules/hesi/assets/main.scss';

const studentRegistrationRoutes = createUserRoutes(ALCRoutes.getExamGroupStudentRegistrationRoutes());

const HESIApp = () => (
  <ErrorBoundary>
    <Switch>
      {studentRegistrationRoutes}
      <Route path={ALCRoutePath.examOrderHistory.examOrderHistory} component={ExamOrderHistoryHomePage} />
      <Route path={ALCRoutePath.examOrderDashboard.examOrderDashboard} component={ExamOrderDashboardHomePage} />
      <Route path={ALCRoutePath.examOrder.home} component={ExamOrderHomePage} />
      <Redirect exact from={ALCRoutePath.rootPath} to={ALCRoutePath.examOrder.home} />
      <Route path={ALCRoutePath.rootPath} component={SecurityPage} />
      <Redirect to={ALCRoutePath.error.pageNotFound} />
    </Switch>
  </ErrorBoundary>
);

export default HESIApp;
