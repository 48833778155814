import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@els/els-react--loader';
import { REDIRECT_URL, RoutePath } from 'constants/app.constant';
import { StorageHelper } from 'helpers/storage.helper';
import { appSelectors } from 'redux/app';
import { locationAsyncActions } from 'redux/location';
import { securitySelectors } from 'redux/security';

const withUserAuthorize = <P extends object>(WrappedComponent: ComponentType<P>) => (props) => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isLoading = useSelector(appSelectors.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    StorageHelper.removeItem(REDIRECT_URL);
  }, []);

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      const redirectUrl = `${props.location.pathname}${props.location.search || ''}`;
      StorageHelper.setItem(REDIRECT_URL, redirectUrl);
      dispatch(locationAsyncActions.redirect(RoutePath.security.login));
    }
  }, [isLoggedIn, isLoading, dispatch, props.location.pathname, props.location.search]);

  return isLoggedIn ? <WrappedComponent {...props} /> : <Loader isBlocking isVisible />;
};

export default withUserAuthorize;
