/* eslint-disable */
import moment from 'moment';
import { UserRole } from '../constants/app.constant';
import { OpportunityModel, OpportunityAddressModel } from '../models/opportunity.model';

const ADDRESS: OpportunityAddressModel = {
  Street: '1234 West St',
  City: 'Townville',
  AptSuite: 'Suite 2A',
  State: 'PA',
  PostalCode: '12345',
  Country: 'USA'
};

export const opportunities: OpportunityModel[] = [
  {
    OpportunityID: 'A1',
    OpportunityIDNumber: 1,
    AccountName: 'AElsevierUniv',
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    AffiliationID: 8599,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: moment().add(1, 'y').toISOString(),
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    Cohorts: [
      {
        CohortID: '41',
        CohortName: 'Spring 2020 Cohort A',
        Program: 'BSN',
        Status: ['Digital Mapping Consultation', 'Cohort Set Up'],
        StartDate: '2020-01-15',
        EndDate: '2022-07-15',
        CohortCount: 25,
        Package: {
          PackageID: '1',
          PackageName: 'Elsevier 360',
          CostPerStudent: 5,
          TotalCost: '$50'
        },
        LastUpdatedDate: new Date('01/15/2020')
      }
    ],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'In Process',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 'A2',
    OpportunityIDNumber: 2,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    AccountName: 'AElsevierUniv2',
    AffiliationID: 8600,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    Cohorts: [
      {
        CohortID: '41',
        CohortName: 'Spring 2020 Cohort A',
        Program: 'BSN',
        Status: ['Product Selections'],
        StartDate: '2020-01-15',
        EndDate: '2022-07-15',
        CohortCount: 25,
        Package: {
          PackageID: '1',
          PackageName: 'Elsevier 360',
          CostPerStudent: 5,
          TotalCost: '$50'
        },
        LastUpdatedDate: new Date('01/15/2020')
      }
    ],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'Active',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 'B2',
    OpportunityIDNumber: 3,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    AccountName: 'BElsevierUniv2',
    AffiliationID: 8602,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'Active',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 'abc1234',
    OpportunityIDNumber: 4,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    AccountName: 'ElsevierUniv',
    AffiliationID: 8599,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    BillingAddress:ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'Inactive',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 'abc123',
    OpportunityIDNumber: 5,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    AccountName: 'ElsevierUniv',
    AffiliationID: 8599,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'In Process',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 'A10',
    OpportunityIDNumber: 6,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'New',
    AccountName: 'ZElsevierUniv1_altered',
    AffiliationID: 8601,
    ECRID: '2',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    SalesTerritoryID: 5,
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS,
    Contract: {
      ContractID: '123',
      ContractStatus: 'In Process',
      ContractAgreement: 'Agr1Name'
    }
  },
  {
    OpportunityID: 388791449,
    OpportunityIDNumber: 7,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '100',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 595587763,
    OpportunityIDNumber: 8,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'New',
    ECRID: '100',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 182315994,
    OpportunityIDNumber: 9,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '100',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'active' as const,
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 574034993,
    OpportunityIDNumber: 10,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '101',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College',
    SalesTerritoryID: 27002,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 109029509,
    OpportunityIDNumber: 11,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '101',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College',
    SalesTerritoryID: 27002,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 533533477,
    OpportunityIDNumber: 12,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '101',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College',
    SalesTerritoryID: 27002,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 388367333,
    OpportunityIDNumber: 13,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '101',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College',
    SalesTerritoryID: 27002,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 980829606,
    OpportunityIDNumber: 14,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '102',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Washington University in St. Louis',
    SalesTerritoryID: 27003,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 439371486,
    OpportunityIDNumber: 15,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '102',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Washington University in St. Louis',
    SalesTerritoryID: 27003,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 573247543,
    OpportunityIDNumber: 16,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '102',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Washington University in St. Louis',
    SalesTerritoryID: 27003,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 333483661,
    OpportunityIDNumber: 17,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '102',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Washington University in St. Louis',
    SalesTerritoryID: 27003,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 329050083,
    OpportunityIDNumber: 18,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '103',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 331844223,
    OpportunityIDNumber: 19,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '103',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'in-progress',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 840130307,
    OpportunityIDNumber: 20,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '103',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Saint Louis University',
    SalesTerritoryID: 27001,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 203261908,
    OpportunityIDNumber: 23,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '104',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Missouri-St. Louis',
    SalesTerritoryID: 27004,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 651775956,
    OpportunityIDNumber: 12453,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '104',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Missouri-St. Louis',
    SalesTerritoryID: 27004,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 350557086,
    OpportunityIDNumber: 12365,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '104',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Missouri-St. Louis',
    SalesTerritoryID: 27004,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 126108095,
    OpportunityIDNumber: 123323,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '105',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Webster University School of Education',
    SalesTerritoryID: 27005,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty],
    BillingAddress: ADDRESS,
    ShippingAddress: ADDRESS
  },
  {
    OpportunityID: 483912047,
    OpportunityIDNumber: 12354,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '105',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Webster University School of Education',
    SalesTerritoryID: 27005,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 583912973,
    OpportunityIDNumber: 12223,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '105',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Webster University School of Education',
    SalesTerritoryID: 27005,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 621636925,
    OpportunityIDNumber: 12433,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '105',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Webster University School of Education',
    SalesTerritoryID: 27005,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 227371814,
    OpportunityIDNumber: 12553,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '106',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Maryville University',
    SalesTerritoryID: 27006,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 670232831,
    OpportunityIDNumber: 12377,
    OpportunityName: 'Program Solution Opportunity – ADN',
    OpportunityType: 'Renewal',
    ECRID: '106',
    CohortProgram: 'ADN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Maryville University',
    SalesTerritoryID: 27006,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  },
  {
    OpportunityID: 662101808,
    OpportunityIDNumber: 12663,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '106',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Maryville University',
    SalesTerritoryID: 27006,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 589191307,
    OpportunityIDNumber: 12883,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '106',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Maryville University',
    SalesTerritoryID: 27006,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 629141335,
    OpportunityIDNumber: 12399,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '107',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Fontbonne University',
    SalesTerritoryID: 27007,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 485324957,
    OpportunityIDNumber: 12003,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '107',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Fontbonne University',
    SalesTerritoryID: 27007,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 187356654,
    OpportunityIDNumber: 12223,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '107',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Fontbonne University',
    SalesTerritoryID: 27007,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 840022591,
    OpportunityIDNumber: 12223,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '108',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'George Warren Brown School of Social Work',
    SalesTerritoryID: 27008,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 607498763,
    OpportunityIDNumber: 12333,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '108',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'George Warren Brown School of Social Work',
    SalesTerritoryID: 27008,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 633163599,
    OpportunityIDNumber: 12223,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '109',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Missouri Baptist University',
    SalesTerritoryID: 27009,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  },
  {
    OpportunityID: 812082591,
    OpportunityIDNumber: 12553,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '110',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Harris-Stowe State University',
    SalesTerritoryID: 27010,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 280989545,
    OpportunityIDNumber: 1233333,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '110',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Harris-Stowe State University',
    SalesTerritoryID: 27010,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 233054659,
    OpportunityIDNumber: 123555,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '111',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Health Sciences and Pharmacy in St. Louis',
    SalesTerritoryID: 27011,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 100495022,
    OpportunityIDNumber: 123222,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '111',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Health Sciences and Pharmacy in St. Louis',
    SalesTerritoryID: 27011,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  },
  {
    OpportunityID: 237733463,
    OpportunityIDNumber: 122223,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '112',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Health Sciences and Pharmacy in St. Louis',
    SalesTerritoryID: 27011,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 237733464,
    OpportunityIDNumber: 1222333,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '112',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Health Sciences and Pharmacy in St. Louis',
    SalesTerritoryID: 27011,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 237733465,
    OpportunityIDNumber: 143323,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '112',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'University of Health Sciences and Pharmacy in St. Louis',
    SalesTerritoryID: 27011,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 902664892,
    OpportunityIDNumber: 11343423,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '113',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Ranken Technical College',
    SalesTerritoryID: 27012,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  },
  {
    OpportunityID: 861853249,
    OpportunityIDNumber: 14323,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '114',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College - Meramec',
    SalesTerritoryID: 27013,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith',
    editRoles: [UserRole.Admin, UserRole.Manager, UserRole.Student, UserRole.Faculty]
  },
  {
    OpportunityID: 861853239,
    OpportunityIDNumber: 12873,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '114',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'St. Louis Community College - Meramec',
    SalesTerritoryID: 27013,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  },
  {
    OpportunityID: 647903765,
    OpportunityIDNumber: 120983,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '115',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Concordia Seminary',
    SalesTerritoryID: 27014,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 171811448,
    OpportunityIDNumber: 129873,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '116',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Lutheran School of Nursing',
    SalesTerritoryID: 27015,
    Status: 'active',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Smith'
  },
  {
    OpportunityID: 188759621,
    OpportunityIDNumber: 1098723,
    OpportunityName: 'Program Solution Opportunity – BSN',
    OpportunityType: 'Renewal',
    ECRID: '116',
    CohortProgram: 'BSN',
    FirstCohortStartDate: '2020-01-15',
    AccountName: 'Lutheran School of Nursing',
    SalesTerritoryID: 27015,
    Status: 'expired',
    OpportunityOwner: {
      ID: 111,
      FullName: 'John Doe',
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'johndoe@gmail.com'
    },
    KeyDecisionMakers: 'John Does'
  }
];
