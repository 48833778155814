import React from 'react';
import { compose } from 'recompose';
import { ELSWithModalService } from '@els/els-component-modal-react';
import SideDrawerModal from './side-drawer-modal/SideDrawerModal.component';
import { NavigationConfig } from '../../../models/navigation.models';
import { ELSModalServiceType } from '../../../models/els.model';
import SideNav from '../side-nav/SideNav.component';
import { ELSButtonWithIcon } from '../../common/els';

export const openNavModal = (event, navConfig, modalService) => {
  event.preventDefault();
  const modalId = 'sideDrawerModal';
  const modalProps = {
    modalId,
    closeHandler: modalService.closeModal
  };
  modalService.openCustomModal({
    modalId,
    modal: (
      <SideDrawerModal {...modalProps}>
        <div className="c-tsp-side-drawer">
          <SideNav navConfig={navConfig} />
        </div>
      </SideDrawerModal>
    ),
    focusSelector: '#modal-button'
  });
};

export interface SideDrawerProps {
  navConfig: NavigationConfig;
  modalService: ELSModalServiceType;
}
const SideDrawer = ({ navConfig, modalService }: SideDrawerProps) => (
  <div className="o-els-flex-layout
                  o-els-flex-layout--right
                  u-els-display-none
                  u-els-display-block@tablet">
    <div className="o-els-flex-layout__item">
      <ELSButtonWithIcon
        size="large"
        className="c-els-menu__button c-els-button c-els-button--icon c-els-button--icon-only c-els-button--blank"
        sprite="Menu"
        onClick={event => openNavModal(event, navConfig, modalService)}><span />
      </ELSButtonWithIcon>
    </div>
  </div>
);

const SideDrawerWrapper = compose(ELSWithModalService)(SideDrawer);

SideDrawerWrapper.displayName = 'SideDrawer';
export default SideDrawerWrapper;
