import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants, DEFAULT_HESI_ASSESS_ROLES } from '../../constants/hesi.constants';

export const REDUCER_ID = ALCConstants.redux.EXAM_ORDER_STATE;

const initialState = {
  accessRoles: []
};

export const hesiSlice = createSlice({
  name: 'hesi',
  initialState,
  reducers: {
    setHesiAccessRoles(state, action: PayloadAction<string>) {
      const hesiAccessRoles = action.payload ? action.payload.split(',') : DEFAULT_HESI_ASSESS_ROLES;
      return {
        ...state,
        accessRoles: hesiAccessRoles
      };
    }
  }
});

export const hesiActions = hesiSlice.actions;
export const hesiReducer = hesiSlice.reducer;
