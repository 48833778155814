import { TSP_APP_CONTEXT } from '../../../../constants/app.constant';
import { ALCRoutePath } from '../../constants/hesi.constants';
import { getNheProductLaunchConfig } from '../../configs/hesi.config';

export const isHesiAlcApp = () => {
  const hesiAlcBaseUrl = `${TSP_APP_CONTEXT}${ALCRoutePath.rootPath}`;
  return window.location.href.includes(hesiAlcBaseUrl);
};

export const isHesiAlcAppWithNheProductLaunchEnabled = () => {
  const nheProductLaunchConfig = getNheProductLaunchConfig();
  return isHesiAlcApp() && nheProductLaunchConfig.enable;
};
