import * as yup from 'yup';

export enum FulfillmentStatusType {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED'
}

export const MAX_LENGTH_COMMENT = 1024;

export const MAX_LENGTH_COMMENT_ERROR_MESSAGE = 'A comment cannot be more than 1024 characters.';

export const PRODUCT_APPROVED_VALIDATION_SCHEMA = {
  comment: yup.string().required('A comment is required when marking a product list as approved.')
};

export const PRODUCT_REJECTED_VALIDATION_SCHEMA = {
  comment: yup.string().required('A comment is required when rejecting a product list.')
};

export const COHORT_COUNT_REJECTED_VALIDATION_SCHEMA = {
  comment: yup.string().required('A comment is required when rejecting a cohort count list.')
};

export enum PRODUCT_PUBSTATUS {
  CANCELLED = 'cancelled',
  OUT_OF_PRINT = 'out of print'
}

export const SEARCH_PRODUCT_INFO = 'searchProductInfo';

export enum ProductApprovalStatus {
  ProductSelectionsApprovalRequestSent = 1,
  ProductSelectionsApproved = 2,
  ProductSelectionsRejected = 3
}

export enum PLATFORM_COURSE_TYPE {
  SUB_COMBO_COURSE = 'sub_combo_course'
}

export enum PRODUCT_ERROR_TYPES {
  PRODUCT_NYP = 'product_status_invalid_not_yet_published',
  NOT_FOUND ='not_found'
}

export const PRODUCT_GROUPS = {
  ALL_STUDENTS: { name: 'All Students', value: '-1', tspGroupId: -1 },
  NO_STUDENTS: { name: 'No Students', value: '-2', tspGroupId: -2 }
};

export const DefaultFilterProductOptions = [
  PRODUCT_GROUPS.NO_STUDENTS,
  PRODUCT_GROUPS.ALL_STUDENTS
];

export const ProductEntitlementInitiatedMessage =
  'Entitlement may take up to 1 hour. As each student is given access their names will be visible by clicking the entitlement icon found next to the "Entitle" button.';
export const FullfillmentCompletedMessage = 'You may close this message to continue.';

export const CombinationCourseWarningMessage = 'Reminder! Ensure all component product ISBNs have been added to the cohort for revenue allocation.';
