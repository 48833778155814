import React, { Component, Suspense } from 'react';
import { History } from 'history';
import { Provider } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IntlProvider } from 'react-intl';
import { isNil } from 'lodash';
import { ConnectedRouter } from 'connected-react-router';
import { ELSIdleProvider, ELSAccessibilityFocusState, ELSTokenServiceRegistrar } from '@els/els-ui-common-react';
import { Loader } from '@els/els-react--loader';
import { ELSModalProvider } from '@els/els-component-modal-react';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { idleTimeout, idleWarningTime, setDefaultAppConfig, tspStateVersion } from 'config/app.config';
import withHTMLHeadSEO from 'hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import LocationChangeHandler from 'components/location-change-handler/LocationChangeHandler.component';
import 'assets/scss/main.scss';
import history from 'helpers/history.helper';
import { configureStore } from 'redux/app.store';
import { RoutePath } from '../constants/app.constant';
import { refreshSessionInterval } from '../config/app.config';
import { securityAsyncActions, securitySelectors } from '../redux/security';
import { appAsyncActions } from '../redux/app';
import { getLanguageMessages } from '../utilities/i18n/i18n.utility';
import { isLocalStorageChange } from '../utilities/app/app.utility';
import { clearTSPState, getTSPStateVersion, StorageHelper, storeTSPStateVersion } from '../helpers/storage.helper';
import { TokenHelper } from '../helpers/token.helper';
import AdobeAnalytics from './common/adobe-analytics/AdobeAnalytics.component';
import AppRoutes from './AppRoutes';
import { isHesiAlcAppWithNheProductLaunchEnabled } from '../modules/hesi/utilities/hesi/hesi.utility';

class App extends Component {
  history: History;
  store: Store;
  sessionTimeoutId: ReturnType<typeof setTimeout>;
  searchParams = new URLSearchParams(window.location.search);

  constructor(props) {
    super(props);
    this.initialize();
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleTokenUpdated);
    this.cleanSessionTimeout();
  }

  initialize = () => {
    setDefaultAppConfig();
    this.clearOldStateIfUpgraded();
    this.clearStateForNewUser();
    this.history = history;
    this.store = configureStore(this.history);
    this.loadAppConfig();
    this.checkIntegrationLogin();
    this.initializeListeners();
  };

  initializeListeners = () => {
    window.addEventListener('storage', this.handleTokenUpdated);
    this.registerSessionRefresh();
  }

  cleanSessionTimeout = () => {
    if (this.sessionTimeoutId) {
      clearTimeout(this.sessionTimeoutId);
      this.sessionTimeoutId = null;
    }
  }

  registerSessionRefresh = () => {
    this.cleanSessionTimeout();
    this.sessionTimeoutId = setTimeout(() => this.refreshSessionId(), refreshSessionInterval);
  };

  refreshSessionId = () => {
    const state = this.store.getState();
    const sessionId = securitySelectors.getSessionId(state);
    const isLoggedIn = securitySelectors.isLoggedIn(state);
    if (!isLoggedIn || !sessionId) {
      return;
    }
    const userRole = securitySelectors.getRoleId(state);
    (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
      securityAsyncActions.refreshSessionId(sessionId, userRole, this.registerSessionRefresh)
    );
  };

  handleTokenUpdated = (e: StorageEvent) => {
    if (isLocalStorageChange(e.storageArea)) {
      (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
        securityAsyncActions.handleTokenUpdated()
      );
    }
  };

  clearOldStateIfUpgraded = () => {
    const prevStateVersion = getTSPStateVersion();
    if (!isNil(prevStateVersion) && prevStateVersion !== tspStateVersion) {
      clearTSPState();
    }
    storeTSPStateVersion(tspStateVersion);
  };

  clearStateForNewUser = () => {
    const tokenFromParam = this.searchParams.get('token');
    if (!tokenFromParam) {
      return;
    }
    ELSTokenServiceRegistrar.register(tokenFromParam);
    const previousUser = StorageHelper.getEvolveUser();
    let currentUser = TokenHelper.getEvolveUser();
    if (isHesiAlcAppWithNheProductLaunchEnabled() && currentUser && !currentUser.userName) {
      currentUser = TokenHelper.getEolsUser();
    }
    if (previousUser && currentUser && previousUser.userName !== currentUser.userName) {
      clearTSPState();
    }
  }

  loginViaTokenParam = () => {
    const tokenFromParam = this.searchParams.get('token');
    (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
      securityAsyncActions.authByTokenParam()
    );

    if (tokenFromParam) {
      this.searchParams.delete('token');
      this.history.replace({
        search: this.searchParams.toString()
      });
    }
  }

  checkIntegrationLogin = () => {
    const tokenFromParam = this.searchParams.get('token');
    if (tokenFromParam) {
      const sessionId = StorageHelper.getEvolveSessionId();
      if (isHesiAlcAppWithNheProductLaunchEnabled() && sessionId) {
        (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
          securityAsyncActions.authByUsingEvolveSession()
        );
      } else {
        this.loginViaTokenParam();
      }
      return;
    }

    (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
      securityAsyncActions.authByEvolveSession(false)
    );
  };

  loadAppConfig = () => {
    (this.store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>)(
      appAsyncActions.fetchAppConfig()
    );
  }

  handleSessionTimeout = () => {
    this.history.push(RoutePath.security.logout);
  };

  render() {
    const { locale, languageMessages } = getLanguageMessages(navigator.language.split(/[-_]/)[0]);
    const HTMLHeadSEOComponent = withHTMLHeadSEO(null)(null);
    return (
      <Provider store={this.store}>
        <ConnectedRouter history={this.history}>
          <IntlProvider locale={locale} messages={languageMessages}>
            <ELSModalProvider>
              <>
                <AdobeAnalytics />
                <LocationChangeHandler />
                <HTMLHeadSEOComponent />
                <Suspense fallback={<Loader isBlocking isVisible />}>
                  <AppRoutes />
                </Suspense>
                <ELSAccessibilityFocusState />
                <ELSIdleProvider timeout={idleTimeout} warningTime={idleWarningTime} onSessionTimeout={this.handleSessionTimeout} />
              </>
            </ELSModalProvider>
          </IntlProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
