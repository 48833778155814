import React from 'react';
import nonSolusLogo from 'assets/icons/NonSolusLogo.svg';
import eolveLogo from 'assets/icons/EvolveLogo.svg';
import { navigateToExternalUrl } from 'utilities/navigation/navigation.utility';
import { getEvolveUrl } from 'config/app.config';
import { ELSButton } from '../els';

interface LogoProps {
  className?: string
}
const Logo = (props: LogoProps) => (
  <div className={`c-tsp-logo ${props.className || ''}`}>
    <ELSButton type="link"
      className="c-els-header__logo-link o-els-flex-layout o-els-flex-layout--middle c-els-link--with-icon"
      aria-label="Elsevier Evolve"
      onClick={() => navigateToExternalUrl(`${getEvolveUrl()}`)}>
      <img src={nonSolusLogo} alt="Elsevier Non Solus logo" className="u-els-margin-right-3o4" width="58" height="58" />
      <img src={eolveLogo} alt="Evolve logo" className="u-els-display-none@mobile" />
    </ELSButton>
  </div>
);

export default Logo;
