import { ELSCommonUIConstants, ELSStorageHelper } from '@els/els-ui-common-react';
import { AppConstant } from '../constants/app.constant';

const getDefaultNamespace = () => {
  const defaultDriver = ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver);
  return defaultDriver.namespace(ELSCommonUIConstants.security.Locker.DefaultNamespace);
};

const getSecondaryNamespace = () => {
  const secondaryDriver = ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.SecondaryDriver);
  return secondaryDriver.namespace(ELSCommonUIConstants.security.Locker.DefaultNamespace);
};

export const StorageHelper = {
  getEvolveUser: () => {
    const defaultNamespace = getDefaultNamespace();
    const evolveUser = defaultNamespace.get(AppConstant.Locker.EvolveUserCacheKey);
    if (!evolveUser) {
      return evolveUser;
    }
    evolveUser.userId = defaultNamespace.get(AppConstant.Locker.EolsUserIdCacheKey);
    return evolveUser;
  },
  getEvolveSessionId: () => {
    const evolveUser = StorageHelper.getEvolveUser();
    return evolveUser && evolveUser.sessionId;
  },
  isLoggedIn: () => getDefaultNamespace().get(AppConstant.Locker.LoggedInCacheKey) === true,
  getRole: () => getDefaultNamespace().get(AppConstant.Locker.RoleCacheKey),
  storeRole: role => getDefaultNamespace().put(AppConstant.Locker.RoleCacheKey, role),
  getUserName: () => getDefaultNamespace().get(AppConstant.Locker.UserNameCacheKey),
  clearTSPState: () => {
    const defaultNamespace = getDefaultNamespace();
    defaultNamespace.remove(AppConstant.Locker.TSPStateCacheKey);
    defaultNamespace.remove(AppConstant.Locker.LoggedInCacheKey);
    defaultNamespace.remove(AppConstant.Locker.EolsUserIdCacheKey);
    defaultNamespace.remove(AppConstant.Locker.EolsExternalUserIdCacheKey);
    defaultNamespace.remove(AppConstant.Locker.EvolveUserCacheKey);
  },
  getTSPState: () => getDefaultNamespace().get(AppConstant.Locker.TSPStateCacheKey),
  storeTSPState: state => getDefaultNamespace().put(AppConstant.Locker.TSPStateCacheKey, state),
  getTSPStateVersion: () => getDefaultNamespace().get(AppConstant.Locker.TSPStateVersionCacheKey),
  storeTSPStateVersion: version => getDefaultNamespace().put(AppConstant.Locker.TSPStateVersionCacheKey, version),
  getEolsExternalUserId: () => getDefaultNamespace().get(AppConstant.Locker.EolsExternalUserIdCacheKey),
  getCampaignCode: () => getDefaultNamespace().get(AppConstant.Locker.CampaignCode),
  IsSalesDemoRole: () => getDefaultNamespace().get(AppConstant.Locker.IsSalesDemoRole),
  storeLoggedIn: loggedIn => getDefaultNamespace().put(AppConstant.Locker.LoggedInCacheKey, loggedIn),
  storeEvolveUser: evolveUser => getDefaultNamespace().put(AppConstant.Locker.EvolveUserCacheKey, evolveUser),
  setItem: (key, value) => getDefaultNamespace().put(key, value),
  getItem: (key) => getDefaultNamespace().get(key),
  removeItem: key => getDefaultNamespace().remove(key)
};

export const SecondaryStorageHelper = {
  clearTSPState: () => {
    const secondaryNamespace = getSecondaryNamespace();
    secondaryNamespace.remove(AppConstant.Locker.TSPStateCacheKey);
  },
  getTSPState: () => getSecondaryNamespace().get(AppConstant.Locker.TSPStateCacheKey),
  storeTSPState: state => getSecondaryNamespace().put(AppConstant.Locker.TSPStateCacheKey, state)
};

export const getTSPStateVersion = () => StorageHelper.getTSPStateVersion();
export const storeTSPStateVersion = version => StorageHelper.storeTSPStateVersion(version);
export const clearTSPState = () => {
  StorageHelper.clearTSPState();
  SecondaryStorageHelper.clearTSPState();
};
