import React from 'react';
import { compose } from 'recompose';
import { Redirect, Switch } from 'react-router-dom';
import { createUserRoutes } from 'utilities/route/route.utility';
import withLoading from 'hocs/with-loading/withLoading.hoc';
import { ALCRoutes } from 'modules/hesi/routes/hesi.routes';
import HESILayout from 'modules/hesi/components/layout/HESILayout.component';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import { HESISecutityRoutes } from 'modules/hesi/routes/secutity.routes';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';

const hesiExamOrderHistoryRoutes = createUserRoutes(ALCRoutes.getExamOrderHistoryRoutes());
const hesiSecurityRoutes = createUserRoutes(HESISecutityRoutes.getRoutes(ALCRoutePath.examOrderHistory.error));

const ExamOrderHistoryHomePage: React.FC = () => (
  <HESILayout>
    <ErrorBoundary>
      <Switch>
        {hesiSecurityRoutes}
        {hesiExamOrderHistoryRoutes}
        <Redirect to={ALCRoutePath.examOrderHistory.error.pageNotFound} />
      </Switch>
    </ErrorBoundary>
  </HESILayout>
);
const enhancers = [withLoading];
export default compose(...enhancers)(ExamOrderHistoryHomePage);
