import { flow, isEmpty, sortBy, uniqBy } from 'lodash';
import { filterExamsByProgramExamTypeFilter, filterExamsBySearchText } from 'modules/hesi/utilities/exam-order/exam.utility';
import { PagingOptions } from '../../models/exam.model';
import { ALCConstants, defaultExamPagingOptions } from '../../constants/hesi.constants';
import { assessmentTypeFields } from '../../constants/exam-order.constant';

const REDUCER_ID = ALCConstants.redux.EXAM_ORDER_STATE;
export const EXAM_FIELD_PATH = {
  NAME: 'name',
  PRICE: 'price',
};

export const isAuthorizeExamOrder = globalState => globalState[REDUCER_ID].isAuthorized;
export const getProgramTypes = globalState => globalState[REDUCER_ID].programTypes;
export const getExamProducts = globalState => globalState[REDUCER_ID].examProducts;
export const getExamOrderItems = globalState => globalState[REDUCER_ID].examOrderItems;
export const getExamOrderItemSelected = globalState => globalState[REDUCER_ID].examOrderItemSelected;
export const getPrograms = globalState => globalState[REDUCER_ID].programs;
export const getExamOrder = globalState => globalState[REDUCER_ID].examOrder;
export const getOrderHistory = globalState => globalState[REDUCER_ID].orderHistory;

export const getAssessmentTypesBySelectedProgramTypes = globalState => {
  const { programTypes } = globalState[REDUCER_ID];
  const { selectedProgramTypes } = globalState[REDUCER_ID].pagingOptions;
  if (isEmpty(programTypes)) return [];

  const assessmentTypes = programTypes.reduce((result, item) => {
    const itemExamType = isEmpty(selectedProgramTypes) || selectedProgramTypes.includes(item.programTypeId) ? item.examTypes : [];
    const itemAssessmentType = isEmpty(itemExamType) ? [] : itemExamType.map(examType => (examType.assessmentType));
    return [...result, ...itemAssessmentType];
  }, []);

  return sortBy(uniqBy(assessmentTypes, assessmentTypeFields.assessmentTypeId), assessmentTypeFields.assessmentTypeName);
};

export const getExamPagingOptions = (defaultValue?: PagingOptions) => globalState => {
  if (globalState[REDUCER_ID].pagingOptions) {
    return globalState[REDUCER_ID].pagingOptions;
  }
  return defaultValue;
};

export const searchExamProducts = globalState => {
  const { examProducts } = globalState[REDUCER_ID];
  const pagingOptions = globalState[REDUCER_ID].pagingOptions || defaultExamPagingOptions;
  const { query: searchText } = pagingOptions;
  const products = examProducts ? examProducts.products : [];

  const examProductsResult = flow(
    filterExamsBySearchText(searchText),
    filterExamsByProgramExamTypeFilter(pagingOptions)
  )(products);

  const pagingExamProducts = (data) => data && data.slice(
    (pagingOptions.currentPage - 1) * pagingOptions.resultsPerPage,
    Math.min(pagingOptions.currentPage * pagingOptions.resultsPerPage, data.length)
  );

  return ({
    totalResults: examProductsResult && examProductsResult.length,
    products: pagingExamProducts(examProductsResult)
  });
};
