import { PagingOptions } from '../../../models/api.model';
import {
  ExamOrder,
  ExamOrderDashboardFilterParamsModel,
  ExamOrderItem,
  ExamPaymentOrder,
  ExamPaymentOrderStatus,
  HesiExamDetailModel
} from '../models/examOrder.model';
import { buildEvolvePagingOptions } from '../../../helpers/api.helpers';
import { tspServiceApi } from '../../../helpers/RestClient.helper';

export const getExamOrderDashboard = (pagingOptions: PagingOptions, requestParam: ExamOrderDashboardFilterParamsModel) => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    ...requestParam
  };
  return tspServiceApi.post('/hesi/alc/admin/examOrders/search', { data }) as Promise<HesiExamDetailModel>;
};

export const getExamOrderDetails = (hesiExamOrderId: string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}`) as Promise<ExamOrder>;

export const getExamOrderItemsByExamOrderId = (hesiExamOrderId: string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}/examOrderItems`) as Promise<{
    examOrderId: string;
    examOrderItems: ExamOrderItem[];
  }>;

export const getExamOrderPaymentsByExamOrderId = (hesiExamOrderId: string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${hesiExamOrderId}/examPymtOrders`) as Promise<{ examPymtOrders: ExamPaymentOrder[] }>;

export const fetchExamOrderPaymentStatusHistory = (tspHesiExamPymtOrderId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/${tspHesiExamPymtOrderId}/statusHistory`) as Promise<{
    examPymtOrderId: number;
    statusHistory: ExamPaymentOrderStatus[];
  }>;
