import { navigateToExternalUrl } from '../../../utilities/navigation/navigation.utility';
import { getEvolveAccountSettingsUrl, getProperty } from '../../../config/app.config';

export const accountSettingsActionHandler = () => {
  const accountSettingsUrl = getEvolveAccountSettingsUrl(window.location.href);
  navigateToExternalUrl(accountSettingsUrl);
};

export const catalogActionHandler = () => {
  const catalogUrl = `${getProperty('evolvePortalBaseUrl')}/cs/store`;
  navigateToExternalUrl(catalogUrl);
};

export const myEvolveActionHandler = () => {
  const myEvolveUrl = `${getProperty('evolvePortalBaseUrl')}/cs/myEvolve`;
  navigateToExternalUrl(myEvolveUrl);
};

export const helpActionHandler = () => {
  const evolveHelpUrl = getProperty('evolveHelpUrl');
  navigateToExternalUrl(evolveHelpUrl, true);
};

export const orderHistoryActionHandler = () => {
  const orderHistoryUrl = `${getProperty('evolvePortalBaseUrl')}/cs/orderHistory/`;
  navigateToExternalUrl(orderHistoryUrl);
};

export const submitSupportEmailActionHandler = () => {
  const accountSettingsUrl = `${getProperty('evolvePortalBaseUrl')}/cs/account`;
  navigateToExternalUrl(accountSettingsUrl);
};

export const viewSupportHistoryActionHandler = () => {
  const accountSettingsUrl = `${getProperty('evolvePortalBaseUrl')}/cs/account`;
  navigateToExternalUrl(accountSettingsUrl);
};
