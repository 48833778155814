import { ELSRouterHelper } from '@els/els-ui-common-react';
import withHesiAuthorize from 'modules/hesi/hocs/with-hesi-authorize/withHesiAuthorize.hoc';
import withExamOrderAuthorize from '../../hocs/with-exam-order-authorize/withExamOrderAuthorize.hoc';

export const createExamOrderRoutes = routes =>
  routes.map(route => {
    const { roles } = route;
    const routeWithAuthorize = {
      ...route
    };

    if (!roles || roles.length > 0) {
      routeWithAuthorize.component = withExamOrderAuthorize(route.component);
    }

    return ELSRouterHelper.createRoute(routeWithAuthorize);
  });

export const createHesiRoutes = (routes, unAuthorizedUrl?: string) => routes.map(route => {
  const { roles } = route;
  const routeWithAuthorize = {
    ...route
  };

  if (!roles || roles.length > 0) {
    routeWithAuthorize.component = withHesiAuthorize(roles, unAuthorizedUrl)(route.component);
  }

  return ELSRouterHelper.createRoute(routeWithAuthorize);
});
