import { ELSRouterHelper } from '@els/els-ui-common-react';
import withUserAuthorize from 'hocs/with-user-authorize/withUserAuthorize.hoc';
import withAuthorize from '../../hocs/with-authorize/withAuthorize.hoc';

export const createTSPRoutes = routes => routes.map(route => {
  const { roles } = route;
  const routeWithAuthorize = {
    ...route
  };
  if (!roles || roles.length > 0) {
    routeWithAuthorize.component = withAuthorize(roles)(route.component);
  }
  return ELSRouterHelper.createRoute(routeWithAuthorize);
});

export const createUserRoutes = routes => routes.map(route => {
  const { roles } = route;
  const routeWithAuthorize = {
    ...route
  };

  if (!roles || roles.length > 0) {
    routeWithAuthorize.component = withUserAuthorize(route.component);
  }

  return ELSRouterHelper.createRoute(routeWithAuthorize);
});
