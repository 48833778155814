import React from 'react';
import { Switch } from 'react-router';
import { createUserRoutes } from 'utilities/route/route.utility';
import HESILayout from 'modules/hesi/components/layout/HESILayout.component';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import { HESISecutityRoutes } from 'modules/hesi/routes/secutity.routes';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';

const hesiSecurityRoutes = createUserRoutes(HESISecutityRoutes.getRoutes(ALCRoutePath.error));

const SecurityPage: React.FC = () => (
  <HESILayout>
    <ErrorBoundary>
      <Switch>
        {hesiSecurityRoutes}
      </Switch>
    </ErrorBoundary>
  </HESILayout>
);

export default SecurityPage;
