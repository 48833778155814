import React from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { securitySelectors } from 'redux/security';
import { ALCRoutePath, DEFAULT_HESI_ASSESS_ROLES } from 'modules/hesi/constants/hesi.constants';
import { ALCRoutes } from 'modules/hesi/routes/hesi.routes';
import { createHesiRoutes } from 'modules/hesi/utilities/route/route.utility';
import { getHesiNavItems } from 'modules/hesi/utilities/navigation/navigation.utility';
import { HESISecutityRoutes } from 'modules/hesi/routes/secutity.routes';
import withLoading from 'hocs/with-loading/withLoading.hoc';
import { UserRole } from 'constants/app.constant';
import Layout from 'components/layout/Layout.component';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';

const hesiExamOrderDashboardRoutes = createHesiRoutes(ALCRoutes.getExamOrderDashboardRoutes(), ALCRoutePath.examOrderDashboard.error.notAuthorized);
const hesiSecurityRoutes = createHesiRoutes(HESISecutityRoutes.getRoutes(ALCRoutePath.examOrderDashboard.error));

const ExamOrderDashboardHomePage: React.FC = () => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isAuthorized = useSelector(securitySelectors.isAuthorized([UserRole.HesiSupportStakeholder]));
  return (
    <Layout navigateSections={getHesiNavItems(isLoggedIn, isAuthorized, DEFAULT_HESI_ASSESS_ROLES)}>
      <ErrorBoundary>
        <Switch>
          {hesiSecurityRoutes}
          {hesiExamOrderDashboardRoutes}
          <Redirect to={ALCRoutePath.examOrderDashboard.error.pageNotFound} />
        </Switch>
      </ErrorBoundary>
    </Layout>
  );
};

export default compose(withLoading)(ExamOrderDashboardHomePage);
