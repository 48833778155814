import { ELSTokenHelper } from '@els/els-ui-common-react';
import { anonymousNavSections } from 'constants/navigation.constant';
import { hesiAuthorizedNavSections, hesiUnauthorizedNavSections } from 'modules/hesi/constants/navigation.constant';
import { filterNavItemsByUserRoles } from 'utilities/navigation/navigation.utility';
import { getHESIBaseURL, getNheProductLaunchConfig } from '../../configs/hesi.config';
import { evolveServices } from '../../../../services';
import withUseLoading from '../../../../utilities/with-loading/withUseLoading';
import { navigateToExternalUrl } from '../../../../utilities/navigation/navigation.utility';
import { securitySelectors } from '../../../../redux/security';

export const getHesiNavItems = (isLoggedIn: boolean, isAuthorized: boolean, userRoles: string[] = []) => {
  if (!isLoggedIn) {
    return anonymousNavSections;
  }
  let navItems = hesiAuthorizedNavSections;
  if (!isAuthorized) {
    navItems = hesiUnauthorizedNavSections;
  }

  return filterNavItemsByUserRoles(userRoles, navItems);
};

const buildLaunchToHesiNGUrl = async (url: string, sessionId: string) => {
  let hesiBaseURL = getHESIBaseURL();
  const nheProductLaunchConfig = getNheProductLaunchConfig();
  const { hesiTargetEnv } = nheProductLaunchConfig;
  let token = ELSTokenHelper.getToken();
  if (nheProductLaunchConfig.enable && hesiTargetEnv) {
    const result = await evolveServices.fetchNheProductLaunchToken(hesiTargetEnv, sessionId);
    if (result && result.token) {
      token = result.token;
    }
    hesiBaseURL = nheProductLaunchConfig.hesiBaseURL.replace('{TARGET_ENV}', hesiTargetEnv);
  }
  return `${hesiBaseURL}${url}?token=${token}`;
};

export const navigateToHesiNG = (url: string) =>
  withUseLoading(async (dispatch: never, getState) => {
    const sessionId = securitySelectors.getSessionId(getState());
    const launchToHesiNGUrl = await buildLaunchToHesiNGUrl(url, sessionId);
    navigateToExternalUrl(launchToHesiNGUrl);
  });
