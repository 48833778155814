import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash';
import { Loader } from '@els/els-react--loader';
import { locationAsyncActions } from '../../../../redux/location';
import { ALCRoutePath } from '../../constants/hesi.constants';
import { isAuthorizeExamOrder } from '../../redux/exam-order/exam-order.selectors';
import { checkAuthorizeExamOrder } from '../../redux/exam-order/exam-order.actions';
import { securitySelectors } from '../../../../redux/security';
import { StorageHelper } from '../../../../helpers/storage.helper';
import { REDIRECT_URL, RoutePath } from '../../../../constants/app.constant';
import { appSelectors } from '../../../../redux/app';

const withExamOrderAuthorize = WrappedComponent => props => {
  const isLoading = useSelector(appSelectors.isLoading);
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isAuthorized = useSelector(isAuthorizeExamOrder);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      const redirectUrl = `${props.location.pathname}${props.location.search || ''}`;
      StorageHelper.setItem(REDIRECT_URL, redirectUrl);
      dispatch(locationAsyncActions.redirect(RoutePath.security.login));
    }
  }, [isLoading, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !isBoolean(isAuthorized)) {
      dispatch(checkAuthorizeExamOrder());
    }
  }, [isLoggedIn, isAuthorized]);

  useEffect(() => {
    if (isBoolean(isAuthorized) && !isAuthorized) {
      dispatch(locationAsyncActions.redirect(ALCRoutePath.error.notAuthorized));
    }
  }, [isAuthorized]);

  return (isLoggedIn && isAuthorized) ? <WrappedComponent {...props} /> : <><Loader isBlocking isVisible /></>;
};

export default withExamOrderAuthorize;
