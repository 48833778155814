import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.cjs';
import IntlTelInputUtils from 'intl-tel-input/build/js/utils.js';
import 'intl-tel-input/build/css/intlTelInput.css';
import { useEffect } from 'react';

declare global {
  interface Window {
    intlTelInputGlobals?: any;
  }
}

interface OptionsConfig {
  initialCountry?: string;
  autoPlaceholder?: string;
  formatOnDisplay?: boolean;
  formatAsYouType?: boolean,
  utilsScript?: string;
  separateDialCode?: boolean;
  showSelectedDialCode?: boolean;
  preferredCountries?: string[];
  onlyCountries?: string[];
  nationalMode?: boolean;
  countryOrder?: string[];
  showFlags?: boolean;
  allowDropdown?: boolean;
}

interface TspIntlTelInputProp {
  onChangeNumber?: (number: string) => void;
  onChangeValidity?: (isValid: boolean) => void;
  initialValue?: string;
  initOptions?: OptionsConfig;
  usePreciseValidation?: boolean;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
}

const readOnlyOptions = {
  formatOnDisplay: true,
  formatAsYouType: true,
  autoPlaceholder: 'off',
  nationalMode: false,
  showFlags: false,
  showSelectedDialCode: false,
  allowDropdown: false,
  utilsScript: IntlTelInputUtils
};

const initOptions = {
  initialCountry: 'us',
  formatOnDisplay: true,
  formatAsYouType: true,
  autoPlaceholder: 'off',
  nationalMode: false,
  countryOrder: ['us', 'ca'],
  utilsScript: IntlTelInputUtils
};

export type Country = {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: string[] | null;
  nodeById: object;
};

const sortCountries = (countries, countryOrder) => {
  const countryOrderModify = countryOrder.map((country) => country.toLowerCase());
  return countries.sort((a: Country, b: Country): number => {
    const aIndex = countryOrderModify.indexOf(a.iso2);
    const bIndex = countryOrderModify.indexOf(b.iso2);
    const aIndexExists = aIndex > -1;
    const bIndexExists = bIndex > -1;
    if (aIndexExists || bIndexExists) {
      if (aIndexExists && bIndexExists) {
        return aIndex - bIndex;
      }
      return aIndexExists ? -1 : 1;
    }
    return 1;
  });
};

const getNode = (country) => (country && country.nodeById && country.nodeById[0] ? country.nodeById[0] : null);

const processCountriesData = (intlTelInputGlobals, options) => {
  const allCountries = intlTelInputGlobals.getCountryData();
  const { instances } = intlTelInputGlobals;
  if (!allCountries || !instances || !options.countryOrder) {
    return;
  }
  const sortedCountries = sortCountries(allCountries, options.countryOrder);
  Object.keys(instances).forEach(key => {
    if (instances[key] && instances[key].countryList) {
      instances[key].countries = sortedCountries;
      instances[key].countryList.innerHTML = '';
      const { length } = sortedCountries;
      for (let i = 0; i < length; i += 1) {
        const country = sortedCountries[i];
        const node = getNode(country);
        if (node) {
          instances[key].countryList.appendChild(node);
        }
      }
      instances[key].countryList.scrollTop = 0;
    }
  });
};

const TspIntlTelInput = (props: TspIntlTelInputProp) => {
  const initOptionsConfig = props.readOnly ? readOnlyOptions : initOptions;
  const className = props.readOnly ? `iti__tel-input--readonly ${props.className}` : '';
  const propsCustom = { ...props, className, initOptions: { ...initOptionsConfig, ...props.initOptions } };

  const getItiInputGlobals = () => window.intlTelInputGlobals;
  const getItiInput = (e) => {
    const intlTelInputGlobals = getItiInputGlobals();
    if (intlTelInputGlobals && intlTelInputGlobals.getInstance) {
      return intlTelInputGlobals.getInstance(e.target);
    }
    return null;
  };

  const setInputSize = (intlTelInputGlobals) => {
    const { instances } = intlTelInputGlobals;
    Object.keys(instances).forEach(key => {
      if (instances[key] && instances[key].telInput) {
        instances[key].telInput.style.width = `${props.initialValue.length}ch`;
      }
    });
  };

  const onBlurInput = (e) => {
    if (!getItiInput(e)) {
      return;
    }

    const telValue = getItiInput(e).getNumber();
    getItiInput(e).setNumber(telValue);
  };

  useEffect(() => {
    const intlTelInputGlobals = getItiInputGlobals();
    if (!intlTelInputGlobals) {
      return;
    }
    processCountriesData(intlTelInputGlobals, initOptions);
    if (props.readOnly) {
      setInputSize(intlTelInputGlobals);
    }
  }, []);

  return <IntlTelInput {...propsCustom} onBlur={onBlurInput} />;
};

export default TspIntlTelInput;
