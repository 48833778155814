import { ELSCommonConfig } from '@els/els-ui-common-react';

export const HESIServerConstants = {
  DataSource: ELSCommonConfig.appProfile,

  common: {
    payment: {
      redirectURL: '/tsp/alc/order-hesi-exams/exam-order-process/:tspHesiExamPymtOrderId/payment',
      cancelURL: '/tsp/alc/order-hesi-exams/exam-order-process/:tspHesiExamPymtOrderId/cancel'
    },
    hesiBaseURL: 'https://hesi-test2.apps.els-ols.com',
    nheProductLaunchConfig: {
      enable: false
    }
  },
  local: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  dev: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  qa: {},
  test1: {},
  test2: {},
  test3: {},
  test4: {},
  test5: {},
  test6: {
    nheProductLaunchConfig: {
      enable: true,
      hesiTargetEnv: 'test2',
      hesiBaseURL: 'https://hesi-{TARGET_ENV}.apps.els-ols.com'
    }
  },
  stage: {
    hesiBaseURL: 'https://hesi-stage.apps.els-ols.com'
  },
  prod: {
    hesiBaseURL: 'https://hesi.elsevier.com'
  }
};
