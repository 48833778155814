import { baseNavigationItems } from 'constants/navigation.constant';
import DropdownNavSection from 'components/navigation/navigation-sections/DropdownNavSection.component';
import { NavigationItemType, NavigationSectionType } from 'models/navigation.models';

export const hesiNavigationItems: { [key: string]: NavigationItemType } = {
  accountSettings: baseNavigationItems.accountSettings,
  logout: baseNavigationItems.logout
};

export const hesiAuthorizedNavSections: NavigationSectionType[] = [
  {
    key: 'account',
    iconLabel: 'Account',
    iconName: 'person',
    iconPrefix: 'gizmo',
    items: [
      hesiNavigationItems.accountSettings,
      hesiNavigationItems.logout
    ],
    navSectionComponent: DropdownNavSection
  }
];

export const hesiUnauthorizedNavSections: NavigationSectionType[] = [
  {
    key: 'account',
    iconLabel: 'Account',
    iconName: 'person',
    iconPrefix: 'gizmo',
    items: [
      hesiNavigationItems.accountSettings,
      hesiNavigationItems.logout
    ],
    navSectionComponent: DropdownNavSection
  }
];
