import { gatewayServiceApi } from 'helpers/RestClient.helper';
import { StorageHelper } from 'helpers/storage.helper';
import { ContentResource } from '../models/contentList.model';
import { isSalesDemoCourse } from '../utilities/content-list/content-list.utility';

export const getContentList = () => gatewayServiceApi.get('/api/evolveUser/resourceList?role=student&refreshResourceList=false', {
  headers: {
    'evl-session-id': StorageHelper.getEvolveSessionId(),
  }
}) as Promise<ContentResource[]>;

export const findResourceByCourseId = async (courseId: string) => {
  const contentList = await getContentList();
  return contentList.find((p: ContentResource) => p.resourceId === courseId ||
    (p.product.resourceId === courseId && !isSalesDemoCourse(p.product.resourceId)));
};
