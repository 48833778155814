import { gatewayServiceApi } from 'helpers/RestClient.helper';
import { StorageHelper } from 'helpers/storage.helper';

export const validateCourseId = (crsId: string) =>
  gatewayServiceApi.get(`/api/evolveUser/resources?identifier=${crsId}&role=student`);

export const enrollToCourse = (crsId: string) => {
  const endPoint = '/api/evolveUser/course?instantEnroll=true&role=student&source=portal';
  const payload = { courseId: crsId };
  return gatewayServiceApi.post(endPoint, {
    headers: {
      'evl-session-id': StorageHelper.getEvolveSessionId(),
    },
    data: payload,
    useWithCredentials: true });
};
