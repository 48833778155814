import { tspServiceApi } from '../../../helpers/RestClient.helper';
import { ProgramFacultiesModel, ProgramModel, ProgramTypeModel, ExamListModel } from '../models/exam.model';

export const validateOrderingFaculty = () => tspServiceApi.get('/hesi/validateOrderingFaculty') as Promise<{ isAuthorized: boolean }>;

export const fetchPrograms = () => tspServiceApi.get('/hesi/programs') as Promise<{ programs: ProgramModel[] }>;

export const getProductMetadata = () => tspServiceApi.get('/hesi/product/metadata') as Promise<{ programTypes: ProgramTypeModel[] }>;

export const searchExamProducts = (selectedProgramTypes, selectedAssessmentTypes) => {
  const requestBody = {
    size: 200,
    startIndex: 1,
    sortBy: 'name',
    sortOrder: 'asc',
    programTypeNames: selectedProgramTypes || [],
    examTypeNames: selectedAssessmentTypes || []
  };
  return tspServiceApi.post('/hesi/products/search', {
    data: requestBody
  }) as Promise<ExamListModel>;
};

export const fetchFacultiesByProgramId = (programId: string | number) => {
  const selectedProgramId = { params: { programId } };
  return tspServiceApi.get('/hesi/faculties', selectedProgramId) as Promise<ProgramFacultiesModel>;
};
